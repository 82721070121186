import React from "react";
import { Router } from "@reach/router";

import Page from "../templates/page";

const Index = (props) => {
  return (
    <Router>
      <Page {...props} path="/*" />
    </Router>
  );
};

Index.displayName = "Index";

export default Index;
